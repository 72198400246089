<template>
  <div class="containers">
    <div class="doms">
      <div class="title">学历丢失证明</div>
      <div class="inputs">
        {{query.company_name}}
      </div>
      <div class="inputs" style="padding-left: 15px; text-indent: 2em">
        本人毕业证件丢失。本人提交的学历证书为国民教育系列学历证书，并保证该证书的真实性和有效性。如有不实或不符的情况，由本人承担因此造成的一切后果。
      </div>
      <div class="inputs" style="padding-left: 15px">
        提交的学历证书的基本情况：
      </div>
      <div class="inputs">
        <div class="itemTitle">毕业学校：</div>
        <van-field class="inputsItem" v-model="query.school" />
      </div>
      <div class="inputs">
        <div class="itemTitle">学校类别：</div>
        <van-field class="inputsItem" v-model="query.school_type" />
      </div>
      <div class="inputs selfStyle">
        <div class="itemTitle">毕业时间：</div>
        <van-field class="inputs1 inputBorder36" v-model="query.end_year" />
        年
        <van-field class="inputs1 inputBorder36" v-model="query.end_month" />
        月
        <van-field class="inputs1 inputBorder36" v-model="query.end_day" />
        日
      </div>
      <div class="inputs">
        <div class="itemTitle">身份证号：</div>
        <van-field class="inputsItem" v-model="query.ID_number" />
      </div>
      <div class="sign">
        <span class="signLabel">保证人签字:</span>
        <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :bgColor.sync="bgColor" :lineColor="'#000'" />
        <img v-else class="card" :src="query.sign_file" alt="">
      </div>
      <div class="reset" @click="resetSign">重新签名</div>
      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
  </div>
</template>

<script>
import {SaveEducationalLoss, LoadEducationalLoss, uploadFile} from "@/api/paper";

export default {
  data() {
    return {
      query: {},
      bgColor: '',

    }
  },
  created() {
    this.loadData()
  },
  methods: {
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    },
    loadData() {
      LoadEducationalLoss({openId: this.$store.state.info.openId}).then(res => {
        if(res.row) {
          this.query = res.row
        }
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SaveEducationalLoss(this.query).then(res => {
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$store.dispatch('SaveLoseImg', res.filePath)

          this.$router.go(-1)
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submitInfo() {
      if(!this.query.school) {
        return  this.$notify({
          type: "warning",
          message: "请输入学校",
        });
      }
      if(!this.query.school_type) {
        return  this.$notify({
          type: "warning",
          message: "请输入学校类别",
        });
      }
      if(!this.query.ID_number) {
        return  this.$notify({
          type: "warning",
          message: "请输入身份证号码",
        });
      }
      if(!this.query.end_year || !this.query.end_month || !this.query.end_day) {
        return this.$notify({
          type: "warning",
          message: "请将日期填写完整",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.containers {
  box-sizing: border-box;
  padding: 28px;
  font-family: PingFang SC;
  font-size: 12px;
  color: #777777;
  line-height: 16px;
}
.inputs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .itemTitle {
    flex-shrink: 0;
    width: 87px;
    text-align: right;
  }
  .inputsItem {
    font-size: 12px;
    border: 1px solid #DDDDDD;
    padding: 0 5px !important;
  }
}
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.inputs1 {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  text-align: center !important;
  padding: 0 !important;
}
.inputBorder36 {
  width: 36px;
}
.sign {
  margin-top: 50px;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
  }
}
.reset {
  position: absolute;
  right: 10px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #02DBCA;
  color: #02DBCA;
  border-radius: 10px;
  margin-top: 15px;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 60px 0;
  color: #000000;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
